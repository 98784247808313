import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [],
  template: `
    <div role="alert" [class]="classes">
      @if (title) {
        <span class="text-lg font-bold">{{ title }} </span>
      }
      @if (text) {
        {{ text }}
      }
    </div>
  `,
})
export class AlertComponent {
  @Input() type?: 'success' | 'warn' | 'error' | 'info';
  @Input() title?: string;
  @Input() text?: string;

  get classes(): string[] {
    return [
      ...['p-4', 'mb-4', 'max-w-fit', 'text-sm', 'rounded-lg'],
      ...this.typeClasses
    ]
  }

  get typeClasses(): string[] {
    switch (this.type) {
      case "success":
        return ['text-green-800', 'bg-green-50'];
      case "warn":
        return ['text-yellow-800', 'bg-yellow-50'];
      case "error":
        return ['text-red-800', 'bg-red-50'];
      case "info":
      default:
        return ['text-blue-800', 'bg-blue-50'];
    }
  }
}
